import React, {useMemo} from 'react'
import Radar, { RadarConfig } from '@ant-design/charts/lib/radar'
import map from 'lodash/map'
import { RIASCE } from '../../store/holland60'
import {useTheme} from '@material-ui/core'

const ScoreRadar = (props: {
  scores: {
    [key in RIASCE]: number
  }
}) => {
  const { palette } = useTheme()
  const config = useMemo<RadarConfig>(() => ({
    data: map(props.scores, (v, k) => ({ name: k, score: v })),
    xField: 'name',
    yField: 'score',
    meta: {
      score: {
        alias: '分数',
        min: 0,
        max: 10
      },
    },
    xAxis: {
      line: null,
      tickLine: null,
    },
    yAxis: {
      label: {
        offset: 10,
      },
      grid: {
        alternateColor: 'rgba(0, 0, 0, 0.04)',
      },
      tickInterval: 2,
    },
    point: {},
    area: {},
    color: palette.primary.main
  }), [props.scores, palette.primary.main])
  return <Radar {...config} />
}

export default ScoreRadar
