import {AnyAction, combineReducers, configureStore} from '@reduxjs/toolkit'
import { reducer as authn, setupStateSelector as setupAuthN } from './authn'
import notifier from './notifier'
import user, {setupStateSelector as setupUser } from './user'
import holland60, { setupStateSelector as setupHolland60 } from './holland60'
import { createLogger } from 'redux-logger'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {expose} from '../debug'
import migrate from './migrate'

const combinedReducer = combineReducers({
  authn,
  notifier,
  user,
  holland60
})

const persistedReducer = persistReducer({
  key: 'app',
  blacklist: ['notifier'],
  storage,
  version: 1,
  migrate 
}, combinedReducer)
const initialState = persistedReducer(undefined, {type: ''})

const reducer = (state: typeof initialState | undefined, action: AnyAction): typeof initialState => {
  switch (action.type) {
  case 'RESET':
    if (state === undefined) return initialState
    return {
      ...initialState,
      _persist: state._persist
    }
  default:
    return persistedReducer(state, action)
  }
}

export type AppState = ReturnType<typeof persistedReducer>
setupAuthN((state: AppState) => state.authn)
setupUser((state: AppState) => state.user)
setupHolland60((state: AppState) => state.holland60)

const logger = createLogger({
  level: process.env.NODE_ENV === 'development' ? 'log' : 'warn'
})

const store = configureStore({
  reducer,
  middleware: getDefaultMiddleware => 
    getDefaultMiddleware()
      .concat(logger)
})

export default store
export const persistor = persistStore(store)

export type AppDispatch = typeof store.dispatch

expose('store', store)
