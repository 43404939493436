import {useHistory} from 'react-router-dom'
import {useEffectOnce} from 'react-use'
import {useAppDispatch} from '../store/hooks'

const ResetPage = () => {
  const dispatch = useAppDispatch()
  const { push } = useHistory()
  useEffectOnce(() => {
    push('/')
    dispatch({type: 'RESET'})
  })
  return <>Reseting...</>
}

export default ResetPage