import React, {ComponentProps, useCallback, useState} from 'react'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import Collapse from '@material-ui/core/Collapse'
import Typography from '@material-ui/core/Typography'
import {makeStyles} from '@material-ui/core/styles'
import Skeleton from '@material-ui/lab/Skeleton'
import IconButton from '@material-ui/core/IconButton'
import ExpandMore from '@material-ui/icons/ExpandMore'
import {AppState} from '../../store'
import {RIASCE} from '../../store/holland60'

type Explanation = NonNullable<AppState['holland60']['explanations']>['R'] & {
  type: RIASCE
}

const useStyles = makeStyles(theme => ({
  explainCard: {
    '& > .MuiCardContent-root': {
      paddingBottom: 0
    },
    '& > .MuiCardActions-root': {
      paddingTop: 0,
      paddingBottom: 0,
      justifyContent: 'flex-end'
    }
  },
  expandButton: {
    '&.open': {
      transform: 'rotate(180deg)'
    }
  },
}))

const RealExplanationCard = (props: {
  explanation: Explanation
}) => {
  const classes = useStyles()
  const { explanation } = props
  const [expand, setExpand] = useState(false)
  const toggleExpand = useCallback(() => {
    setExpand(!expand)
  }, [expand, setExpand])

  return (
    <Card
      className={classes.explainCard}
      onClick={toggleExpand}
    >
      <CardContent>
        <Typography variant="caption" color="textSecondary">
          {explanation.type} ({explanation.name})
        </Typography>
        <Collapse collapsedHeight="200px" in={expand}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="subtitle1" color="secondary">特征</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                {explanation.features}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1" color="secondary">擅长</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                {explanation.specialties}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1" color="secondary">典型职业</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                {explanation.typicalCareer}
              </Typography>
            </Grid>
          </Grid>
        </Collapse>
      </CardContent>
      <CardActions>
        <IconButton
          className={`${classes.expandButton} ${expand ? 'open' : ''}`}
          onClick={toggleExpand}
        >
          <ExpandMore />
        </IconButton>
      </CardActions>
    </Card>
  )
}

export const ExplanationCardSkeleton = (props: {
  retry?: () => any,
  loading?: boolean
}) => {
  return (
    <Card onClick={props.retry}>
      <CardContent>
        <Skeleton variant="rect" height="150px" animation={props.loading ? 'pulse' : false} />
      </CardContent>
    </Card>
  )
}

export default function ExplanationCard (props: (
  & Omit<ComponentProps<typeof RealExplanationCard>, 'explanation'>
  & ComponentProps<typeof ExplanationCardSkeleton>
  & {
    explanation?: Explanation
  }
)) {
  if (props.explanation === undefined) {
    return <ExplanationCardSkeleton retry={props.retry} loading={props.loading} />
  }
  
  return <RealExplanationCard explanation={props.explanation} />
}

