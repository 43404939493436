import React, {HTMLAttributes, useMemo, useState} from 'react'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import {makeStyles} from '@material-ui/core/styles'
import {useAsyncRetry} from 'react-use'
import {useAppDispatch, useAppSelector} from '../../store/hooks'
import {actions as holland60Actions} from '../../store/holland60'
import Skeleton from '@material-ui/lab/Skeleton'
import {useHistory} from 'react-router-dom'
import toPairs from 'lodash/toPairs'
import orderBy from 'lodash/orderBy'
import mapValues from 'lodash/mapValues'
import ScoreRadar from './ScoreRadar'
import ExplanationCard, {ExplanationCardSkeleton} from './ExplanationCard'
import {RIASCE} from '../../store/holland60'
import RequestSupportDialog from './RequestSupportDialog'
import take from 'lodash/take'

const useStyles = makeStyles(theme => ({
  page: {
    paddingTop: theme.spacing(1)
  },
  cardActions: {
    justifyContent: 'end',
    paddingTop: 0
  },
  bottomButton: {
    color: theme.palette.text.secondary,
    margin: theme.spacing(2)
  }
}))

const ResultPage = (props: {
  result: {
    scores: {
      [key in RIASCE]: number
    },
    subjects: string[]
  }
} & HTMLAttributes<HTMLDivElement>) => {
  const { result } = props
  const classes = useStyles()
  const {replace} = useHistory()
  const [supportDialog, setSupportDialog] = useState(false)
  
  const dispatch = useAppDispatch()
  const explanationsState = useAsyncRetry(() => dispatch(holland60Actions.loadExplanations()))
  const explanations = useAppSelector(state => {
    if (state.holland60.explanations === undefined) return
    
    return mapValues(
      state.holland60.explanations,
      (e, k) => ({...e, type: k as RIASCE})
    )
  })
  const orderedScores = useMemo(() => orderBy(toPairs(result.scores) as [[RIASCE, number]], 1, 'desc'), [result.scores])
  const top3Type = useMemo(
    () => explanations && take(orderedScores, 3)
      .map(([t]) => `${explanations[t].name} (${explanations[t].type})`)
      .join(', '),
    [orderedScores, explanations]
  )
  
  return (
    <Container maxWidth="sm" className={classes.page}>
      <Grid container spacing={1} alignItems="stretch">
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container>
                <Grid item style={{flexGrow: 1}}>
                  <Typography variant="subtitle1" color="textSecondary" noWrap>首选科目推荐:</Typography>
                  <Typography variant="h6" gutterBottom>{result.subjects.join('、')}</Typography>
                </Grid>
                <Grid item>
                  <Button
                    color="secondary"
                    onClick={() => setSupportDialog(true)}
                  >
                    需要帮助?
                  </Button>
                  <RequestSupportDialog
                    open={supportDialog}
                    onClose={() => setSupportDialog(false)}
                  />
                </Grid>
              </Grid>
              <Typography
                variant="subtitle1"
                color="textSecondary"
                noWrap
              >性格类型: {top3Type}</Typography>
              <ScoreRadar scores={result.scores} />
            </CardContent>
          </Card>
        </Grid>
        {
          orderedScores.map(([type]) => (
            <Grid item xs={12} key={type}>
              <ExplanationCard
                explanation={explanations?.[type]}
                retry={explanationsState.retry}
                loading={explanationsState.loading}
              />
            </Grid>
          ))
        }
      </Grid>
      <Grid item style={{textAlign: 'center'}}>
        <Button
          className={classes.bottomButton}
          onClick={() => {
            dispatch(holland60Actions.resetAnswers())
            replace('/')
          }}>
          再做一份
        </Button>
      </Grid>
    </Container>
  )
}

const ResultPageSkeleton = (props: {
  onClick: () => any,
  loading: boolean
}) => {
  const { onClick, loading } = props
  const classes = useStyles()
  
  return (
    <Container maxWidth="sm" className={classes.page} onClick={onClick}>
      <Grid container spacing={1} alignItems="stretch">
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="h6">
                <Skeleton animation={loading ? 'pulse' : false} />
              </Typography>
              <Skeleton variant="rect" height={200} animation={loading ? 'pulse' : false} />
            </CardContent>
          </Card>
        </Grid>
        {
          [0,1,2].map(v => (
            <Grid item xs={12} key={v}>
              <ExplanationCardSkeleton loading={loading}/>
            </Grid>
          ))
        }
      </Grid>
    </Container>
  )
}

const SubmitPage = () => {
  const dispatch = useAppDispatch()
  const result = useAppSelector(state => state.holland60.result)
  const { retry, loading } = useAsyncRetry(() => dispatch(holland60Actions.submit()))
  
  if (result === undefined) {
    return <ResultPageSkeleton onClick={retry} loading={loading} />
  }
  
  return <ResultPage result={result} />
}

export default SubmitPage