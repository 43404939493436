import React, {useCallback, useEffect, useState} from 'react'
import store from '../store'
import {unwrapResult} from '@reduxjs/toolkit'
import {useAsyncRetry, useEffectOnce} from 'react-use'
import {useAppDispatch, useAppSelector} from '../store/hooks'
import {selectors as holland60Selectors, actions as holland60Actions} from '../store/holland60'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Container from '@material-ui/core/Container'
import LinearProgress from '@material-ui/core/LinearProgress'
import Skeleton from '@material-ui/lab/Skeleton'
import Grid from '@material-ui/core/Grid'
import {useHistory} from 'react-router-dom'
import {makeStyles} from '@material-ui/core/styles'
import {parse as parseSearch} from 'query-string'
import {useLocation} from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  card: {
    margin: theme.spacing(1),
  },
  form: {
    paddingTop: theme.spacing(2),
  },
  actions: {
    justifyContent: 'flex-end',
    paddingTop: 0
  },
  answerButton: {
    ...theme.typography.h5,
    width: '100%',
    height: '120px',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    '&.checked': {
      '&.true': {
        backgroundColor: theme.palette.success.dark,
        color: theme.palette.success.contrastText
      },
      '&.false': {
        backgroundColor: theme.palette.error.dark,
        color: theme.palette.error.contrastText
      }
    }
  },
  unansweredProgress: {
    backgroundColor: 'white',
    animation: 'none',
    backgroundImage: 'none'
  }
}))

const QuestionCard = (props: {
  question: {
    question: string,
    answer?: boolean
  },
  onChange: (value: boolean) => any,
  onPrev?: () => any,
  onNext?: () => any,
  onLast?: () => any,
  progress?: React.ReactNode
  index: number,
  total: number,
}) => {
  const classes = useStyles()
  const { question, onChange, onPrev, onNext, onLast, progress } = props
  const answer = question.answer
  
  return (
    <Card className={classes.card}>
      {progress}
      <CardContent>
        <div style={{
          minHeight: 48
        }}>
          ({props.index + 1}/{props.total}) {question.question}
        </div>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Button
              className={`${classes.answerButton} true ${answer === true ? 'checked' : ''}`}
              onClick={() => onChange(true)}
            >
              是
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              className={`${classes.answerButton} false ${answer === false ? 'checked' : ''}`}
              onClick={() => onChange(false)}
            >
              否
            </Button>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions className={classes.actions}>
        <Button disabled={onPrev === undefined} onClick={onPrev}>上一题</Button>
        <Button
          disabled={onNext === undefined}
          onClick={onNext}
          onDoubleClick={onLast}
        >下一题</Button>
      </CardActions>
    </Card>
  )
}

const Holland60Survey = (props: {}) => {
  const classes = useStyles()
  
  const {replace} = useHistory()

  const questions = useAppSelector(state => state.holland60.questions ?? [])
  const nextQuestion = useAppSelector(holland60Selectors.nextQuestion)
  const [current, setCurrent] = useState(nextQuestion)
  useEffect(() => {
    if (nextQuestion === -1) {
      replace('/submit')
    } else {
      setCurrent(nextQuestion)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextQuestion])
  const question = useAppSelector(state => state.holland60.questions?.[current])
  const answered = useAppSelector(holland60Selectors.answered)
  const dispatch = useAppDispatch()
  
  // Callbacks
  const toPrev = useCallback(() => {
    setCurrent(current - 1)
  }, [current, setCurrent])
  const toNext = useCallback(() => {
    setCurrent(current + 1)
  }, [current, setCurrent])
  const toLast = useCallback(() => {
    setCurrent(nextQuestion)
  }, [setCurrent, nextQuestion])
  const setAnswer = useCallback((v: boolean) => {
    dispatch(holland60Actions.setAnswer({
      index: current,
      answer: v
    }))

    if (current < nextQuestion) {
      toNext()
    }
  }, [dispatch, current, nextQuestion, toNext])

  if (question === undefined) {
    return <>Unreachable Code</>
  }
  
  return (
    <Container maxWidth="sm" disableGutters>
      <QuestionCard
        question={question}
        onChange={setAnswer}
        onPrev={current === 0 ? undefined : toPrev}
        onNext={current < nextQuestion ? toNext : undefined}
        onLast={toLast}
        progress={
          <LinearProgress
            variant="buffer"
            value={current / questions.length * 100}
            valueBuffer={answered / questions.length * 100}
            classes={{
              dashedColorPrimary: classes.unansweredProgress
            }}
          />
        }
        index={current}
        total={questions.length}
      />
    </Container>
  )
}

export async function preload() {
  unwrapResult(await store.dispatch(holland60Actions.loadQuestions()))
  return true
}

const Holland60SurveyPreLoad = (props: Parameters<typeof Holland60Survey>[0]) => {
  const classes = useStyles()
  const { reset = false } = parseSearch(useLocation().search)
  const dispatch = useAppDispatch()
  useEffectOnce(() => { if (reset) dispatch(holland60Actions.resetAnswers()) })
  
  const {
    value: loaded,
    loading,
    retry
  } = useAsyncRetry(preload)
  
  if (!loaded) {
    return (
      <Container maxWidth="sm" disableGutters>
        <Card className={classes.card}>
          <CardContent>
            <Skeleton animation={loading ? 'pulse' : false} />
            <Skeleton animation={loading ? 'pulse' : false} />
            <Skeleton animation={loading ? 'pulse' : false} />
          </CardContent>
          <CardActions>
            {
              !loading ?
                <Button color="secondary" onClick={retry}>重新加载</Button>
                : undefined
            }
          </CardActions>
        </Card>
      </Container>
    )
  }
  
  return <Holland60Survey {...props} />
}

export default Holland60SurveyPreLoad