import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import _format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import locale from 'date-fns/locale/zh-CN'

export type DateLike = string | Date

export function parse(date: DateLike): Date {
  if (typeof date === 'string') {
    date = parseISO(date)
  }
  return date
}

export function distanceToNow(date: DateLike) {
  return formatDistanceToNow(parse(date), { locale })
}

export function formatLocale(date: DateLike) {
  return format(date, 'PPpp')
}

export function format(date: DateLike, format: string) {
  return _format(parse(date), format, { locale })
}
