import axios from 'axios'
import {expose} from '../debug'

export const client = axios.create({
  baseURL: '/api/',
  timeout: 15000
})

const tokenProvider = {
  getToken: () => '' as string | null
}

export function setTokenProvider(f: typeof tokenProvider.getToken) {
  tokenProvider.getToken = f
} 

client.interceptors.request.use(
  (config) => {
    let token = tokenProvider.getToken()
    if (token !== null) {
      config.headers['Authorization'] = `Bearer ${token}`
    }
    return config
  }
)

client.interceptors.response.use(
  c => c,
  (error) => {
    if (error.response && error.response.data) {
      let resp = error.response.data
      
      // Valodation error
      if (typeof resp.title === 'string') {
        error = new Error(resp.title)
      }
      
      if (typeof resp.error === 'string') {
        error = new Error(resp.error)
      }
    }

    return Promise.reject(error)
  }
)

expose('api', client)