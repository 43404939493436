import {client} from './'

export async function authNSubmitter() {
  let resp = await client.post<{
    token: string,
    expire: number
  }>('/authn/submitter')
  
  return resp.data.token
}

export async function authNAdmin(password: string) {
  let resp = await client.post<{
    token: string,
    expire: number
  }>('/authn/admin', { password })

  return resp.data.token
}
