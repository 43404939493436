import React, { useCallback, useState } from 'react'

import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import LoopIcon from '@material-ui/icons/Loop'
import GetAppIcon from '@material-ui/icons/GetApp'
import {DatePicker} from '@material-ui/pickers'

import { createStyles, makeStyles } from '@material-ui/core/styles'
import {useAsyncRetry, useAsyncFn} from 'react-use'
import {useSnackbar} from 'notistack'

import pick from 'lodash/pick'
import toPairs from 'lodash/toPairs'
import orderBy from 'lodash/orderBy'
import take from 'lodash/take'
import subDate from 'date-fns/sub'
import startOfDay from 'date-fns/startOfDay'
import endOfDay from 'date-fns/endOfDay'
import {distanceToNow, formatLocale} from '../../utils/date'

import {getResults, exportReports} from '../../api/holland60'

const useStyles = makeStyles(theme => createStyles({
  page: {
    height: '100vh',
    width: '100vw',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'stretch',
    padding: theme.spacing(2)
  },
  card: {
    flexGrow: 1,
    maxWidth: theme.breakpoints.values.lg,
    display: 'flex',
    flexDirection: 'column'
  },
  container: {
    flexGrow: 1
  },
  footer: {
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'row',
    borderTop: `1px solid ${theme.palette.divider}`,
    alignItems: 'center',
    '& .date-input': {
      width: 100,
      padding: theme.spacing(1),
      '& .MuiInputBase-input': {
        textAlign: 'center'
      }
    }
  }
}))

export default function ResultPage() {
  // Utils
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()

  // Pagination
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const onChangePage = useCallback((event: unknown, newPage: number) => {
    setPage(newPage)
  }, [setPage])
  const onChangeRowsPerPage = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }, [setRowsPerPage, setPage])

  // Data
  const [startDate, setStartDate] = useState<Date | null>(subDate(new Date(), {months: 1}))
  const [endDate, setEndDate] = useState<Date | null>(new Date())
  const {
    value: data,
    loading,
    retry
  } = useAsyncRetry(async () => {
    try {
      let from: Date | undefined = undefined
      let to: Date | undefined = undefined
      if (startDate) {
        from = startOfDay(startDate)
      }
      if (endDate) {
        to = endOfDay(endDate)
      }

      return await getResults(page, rowsPerPage, from, to)
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' })
      throw e
    }
  }, [page, rowsPerPage, startDate, endDate])

  // Export
  const [{ loading: downloading }, download] = useAsyncFn(async () => {
    try {
      await exportReports(startDate || undefined, endDate || undefined)
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' })
    }
  }, [enqueueSnackbar, startDate, endDate])

  return (
    <div className={classes.page}>
      <Paper className={classes.card}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell>
                  报告ID
                </TableCell>
                <TableCell>
                  提交时间
                </TableCell>
                <TableCell>
                  联系方式
                </TableCell>
                <TableCell>
                  性格类型
                </TableCell>
                <TableCell>
                  推荐科目
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.rows.map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    <TableCell>
                      {row.id}
                    </TableCell>
                    <Tooltip
                      title={formatLocale(row.createTime)}
                      placement="bottom-start"
                    >
                      <TableCell>
                        {distanceToNow(row.createTime)}前
                      </TableCell>
                    </Tooltip>
                    <TableCell>
                      {
                        row.requireSupport
                          ? <Typography color="textPrimary">
                            {row.phone} ({row.name})
                          </Typography>
                          : <Typography color="textSecondary">不需要</Typography>
                      }
                    </TableCell>
                    <TableCell>
                      {
                        take(orderBy(
                          toPairs(pick(row, ['r', 'i', 'a', 's', 'e', 'c'])),
                          1
                        ), 3).map(([t]) => t.toUpperCase())
                      }
                    </TableCell>
                    <TableCell>
                      {row.suggestSubjects.replaceAll(',', ', ')}
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <div className={classes.footer}>
          <DatePicker
            className="date-input"
            format="PP"
            autoOk
            variant="inline"
            maxDate={endDate}
            value={startDate}
            onChange={setStartDate}
          />
          ~
          <DatePicker
            className="date-input"
            format="PP"
            autoOk
            variant="inline"
            minDate={startDate}
            value={endDate}
            onChange={setEndDate}
          />
          <IconButton
            onClick={download}
            disabled={downloading}
          >
            <GetAppIcon />
          </IconButton>
          <IconButton onClick={retry} disabled={loading}>
            <LoopIcon />
          </IconButton>
          <Typography color="textPrimary" style={{flexGrow: 1}}>
            {loading ? '...' : ''}
          </Typography>
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={data?.total ?? 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
          />
        </div>
      </Paper>
    </div>
  )
}
