import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { requestSupport } from '../../api/holland60'
import { useAppSelector } from '../../store/hooks'
import { useSnackbar } from 'notistack'
import { useAsyncFn } from 'react-use'

export default function RequestSupportDialog(props: {
  open: boolean,
  onClose: () => void,
}) {
  const { open, onClose } = props
  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')

  const { enqueueSnackbar } = useSnackbar()
  
  const resultId = useAppSelector(state => state.holland60.result?.id)
  const [{ loading }, submit] = useAsyncFn(async () => {
    try {
      if (resultId === undefined) throw new Error('没有提交问卷')
      await requestSupport(resultId, { name, phone })
      enqueueSnackbar('提交成功', {
        variant: 'success'
      })
      await new Promise((resolve) => setTimeout(resolve, 500))
      onClose()
    } catch (e) {
      enqueueSnackbar(e.message, {
        variant: 'error'
      })
    }
  }, [name, phone, onClose, resultId, enqueueSnackbar])

  return (
    <div>
      <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">获取帮助</DialogTitle>
        <DialogContent>
          <DialogContentText>
            若您对分析结果存在疑惑，或希望了解更多选课意见，请在下方填写您的联系方式，我们将尽快联系您。
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="姓名"
            type="text"
            fullWidth
            value={name}
            onChange={(event) => setName(event.target.value)}
          />
          <TextField
            margin="dense"
            id="phone"
            label="联系方式"
            type="text"
            fullWidth
            value={phone}
            onChange={(event) => setPhone(event.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary" disabled={loading}>
            取消
          </Button>
          <Button
            onClick={submit}
            color="primary"
            disabled={name === '' || phone === '' || loading}
          >
            提交
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
