import {AsyncThunkPayloadCreator, createAction, createSlice} from '@reduxjs/toolkit'
import {expose} from '../debug'

export type NotifyOption = {
  variant: 'default' | 'error' | 'success' | 'warning' | 'info' | undefined
}

export const actions = {
  enqueue: createAction('notifier/enqueue', (message: string, options?: NotifyOption) => ({
    payload: {
      key: (new Date().getTime() + Math.random()).toString(),
      message,
      options
    }
  })),
  remove: createAction<string, 'notifier/remove'>('notifier/remove'),
  displayed: createAction<string, 'notifier/displayed'>('notifier/displayed')
}
expose('actions.notifier', actions)

const slice = createSlice({
  name: 'notifier',
  initialState: {
    notifications: {} as {
      [key: string]: {
        message: string,
        options?: NotifyOption,
        displayed: boolean
      } | undefined
    }
  },
  reducers: {},
  extraReducers: builder => builder
    .addCase(actions.enqueue, (state, action) => {
      state.notifications[action.payload.key] = {
        ...action.payload,
        displayed: false
      }
    })
    .addCase(actions.remove, (state, action) => {
      delete state.notifications[action.payload]
    })
    .addCase(actions.displayed, (state, action) => {
      const n = state.notifications[action.payload]
      if (n === undefined) return
      n.displayed = true
    })
}) 

export default slice.reducer

export const notifyFailedThunk = <Returned, ThunkArg = void, ThunkApiConfig = {}>(creator: AsyncThunkPayloadCreator<Returned, ThunkArg, ThunkApiConfig>): AsyncThunkPayloadCreator<Returned, ThunkArg, ThunkApiConfig> =>
  async (arg, api) => {
    try {
      return await creator(arg, api)
    } catch (e) {
      api.dispatch(actions.enqueue(e.message, {variant: 'error'}))
      throw e
    }
  }