import {createAction, createAsyncThunk, createSelector, createSlice} from '@reduxjs/toolkit'
import jwtDecode, {JwtPayload} from 'jwt-decode'
import * as authNApi from '../api/authn'
import {notifyFailedThunk} from './notifier'
import {expose} from '../debug'

const initialState = {
  token: null as string | null
}

let stateSelector = (state: any): typeof initialState => {
  throw new Error('invalid state selector')
}
export function setupStateSelector(selector: typeof stateSelector) {
  stateSelector = selector
}
const _sliceSelector = createSelector(_ => _, (state) => stateSelector(state))

const jwtPayload = createSelector(_sliceSelector, (state) => {
  if (state.token === null) return

  try {
    return jwtDecode<JwtPayload & {
      role?: string | string[]
    }>(state.token)
  } catch (e) {
    return
  }
})

const isAuthed = createSelector(jwtPayload, (payload) => {
  if (payload === undefined) return false
  if (payload.exp === undefined) return false
  return payload.exp * 1000 > Date.now()
})

const isAdmin = createSelector([jwtPayload, isAuthed], (payload, isAuthed) => {
  if (!isAuthed) return false
  if (payload?.role === undefined) return false
  if (payload.role === 'Admin') return true
  return payload.role.includes('Admin')
})

export const selectors = { isAuthed, isAdmin }

export const actions = {
  authNSubmitter: createAsyncThunk('authn/authNSubmitter', notifyFailedThunk(() => authNApi.authNSubmitter())),
  authNAdmin: createAsyncThunk('authn/authNAdmin', notifyFailedThunk(authNApi.authNAdmin)),
  setToken: createAction<string | null, 'authn/setToken'>('authn/setToken')
}
expose('actions.authn', actions)

const authnSlice = createSlice({
  name: 'authn',
  initialState,
  reducers: { },
  extraReducers: builder => builder
    .addCase(actions.authNSubmitter.fulfilled, (state, action) => {
      state.token = action.payload
    })
    .addCase(actions.authNAdmin.fulfilled, (state, action) => {
      state.token = action.payload
    })
    .addCase(actions.setToken, (state, action) => {
      state.token = action.payload
    })
})
export const reducer = authnSlice.reducer
