import _set from 'lodash/set'

export function isDev() {
  return process.env.NODE_ENV === 'development'
}

export function expose(path: string, object: any) {
  if (!isDev()) return
  
  _set(global, path, object)
}