import { createMigrate } from 'redux-persist'
import omit from 'lodash/omit'

export default createMigrate({
  0: (state: any) => {
    return {
      ...state,
      holland60: omit(state.holland60, 'result')
    }
  },
  1: (state: any) => {
    return {
      ...state,
      holland60: omit(state.holland60, 'result')
    }
  }
})
