import {createAsyncThunk, createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {notifyFailedThunk} from './notifier'
import {getQuestions, getExplanations, submit as submitSurvey} from '../api/holland60'
import {expose} from '../debug'
import _findIndex from 'lodash/findIndex'

export type RIASCE = 'R' | 'I' | 'A' | 'S' | 'C' | 'E'

const initialState: {
  questions?: ({
    question: string,
    answer?: boolean
  } | undefined)[],
  result?: {
    id: number,
    scores: {
      [key in RIASCE]: number
    },
    subjects: string[]
  },
  explanations?: {
    [key in RIASCE]: {
      name: string,
      features: string,
      specialties: string,
      typicalCareer: string
    }
  }
} = { }

let stateSelector = (state: any): typeof initialState => {
  throw new Error('invalid state selector')
}
export function setupStateSelector(selector: typeof stateSelector) {
  stateSelector = selector
}

const _sliceSelector = createSelector(_ => _, (state) => stateSelector(state))
const nextQuestion = createSelector(_sliceSelector, (state) => _findIndex(state.questions, v => v !== undefined && v.answer === undefined))
export const selectors = {
  nextQuestion,
  answered: createSelector(_sliceSelector, (state) => {
    if (state.questions === undefined) return 0
    
    let r = 0
    for (const q of state.questions) {
      if (q?.answer !== undefined) r++
    }
    return r
  }),
  canSubmit: createSelector([_sliceSelector, nextQuestion], (state, next) => {
    if (state.questions === undefined || state.questions.length === 0) {
      return false
    }

    return next === -1
  })
}
expose('selectors.holland60', selectors)

const extraActions = {
  loadQuestions: createAsyncThunk(
    'holland60/loadQuestions',
    notifyFailedThunk(async (_, { getState }) => {
      const state = stateSelector(getState())
      if (state.questions !== undefined) return state.questions
      return await getQuestions()
    }),
  ),
  loadExplanations: createAsyncThunk(
    'holland60/loadExplanations',
    notifyFailedThunk(async (options: {
      force?: boolean
    } | undefined, { getState }) => {
      if (!(options?.force ?? false)) {
        const state = stateSelector(getState())
        if (state.explanations !== undefined) return state.explanations
      }
      
      return await getExplanations()
    })
  ),
  submit: createAsyncThunk(
    'holland60/submit',
    notifyFailedThunk(async (_, { getState }) => {
      const state = stateSelector(getState())
      if (state.result !== undefined) return state.result
      
      const answers: boolean[] = []
      if (state.questions === undefined) throw new Error('Questions is undefined')
      
      for (const q of state.questions) {
        if (q === undefined) throw new Error('Question is undefined')
        if (q.answer === undefined) throw new Error('部分问题没有回答')
        answers.push(q.answer)
      }
      
      const { id, scores, preferSubjects } = await submitSurvey(answers)
      return {
        id,
        scores,
        subjects: preferSubjects
      }
    })
  )
}

const slice = createSlice({
  name: 'holland60',
  initialState,
  reducers: {
    setAnswer(state, action: PayloadAction<{index: number, answer: boolean}>) {
      if (state.questions === undefined) return
      
      const target = state.questions[action.payload.index]
      if (target === undefined) return
      target.answer = action.payload.answer
    },
    resetAnswers(state) {
      if (state.questions === undefined) return
      
      state.questions = state.questions.map((v) => v && ({
        ...v,
        answer: undefined
      }))
      state.result = undefined
    }
  },
  extraReducers: builder => builder
    .addCase(extraActions.loadQuestions.fulfilled, (state, action) => {
      state.questions = action.payload
    })
    .addCase(extraActions.submit.fulfilled, (state, action) => {
      state.result = action.payload
    })
    .addCase(extraActions.loadExplanations.fulfilled, (state, action) => {
      state.explanations = action.payload
    })

})

export default slice.reducer
export const actions = {...extraActions, ...slice.actions}

expose('actions.holland60', actions)
