import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {expose} from '../debug'

const initialState = {
  clazz: undefined as string | undefined,
  stuNo: undefined as string | undefined,
  name: undefined as string | undefined
}

let stateSelector = (state: any): typeof initialState => {
  throw new Error('invalid state selector')
}
export function setupStateSelector(selector: typeof stateSelector) {
  stateSelector = selector
}

const _sliceSelector = createSelector(_ => _, (state) => stateSelector(state))
export const selectors = {
  isSubmitterInfoFilled: createSelector(_sliceSelector, (state) => {
    return !!(state.clazz && state.stuNo && state.name)
  }),
  submitterInfo: createSelector(_sliceSelector, (state) => {
    if (!(state.clazz && state.stuNo && state.name)) return undefined
    return {
      clazz: state.clazz,
      stuNo: state.stuNo,
      name: state.name
    }
  })
}
expose('selectors.user', selectors)

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setClazz: (state, action: PayloadAction<string | undefined>) => {
      state.clazz = action.payload
    },
    setStuNo: (state, action: PayloadAction<string | undefined>) => {
      state.stuNo = action.payload
    },
    setName: (state, action: PayloadAction<string | undefined>) => {
      state.name = action.payload
    }
  }
})

export default slice.reducer
export const actions = slice.actions