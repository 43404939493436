import {client} from './'
import contentDisposition from 'content-disposition'
import { format } from '../utils/date'

type RIASCE = 'R' | 'I' | 'A' | 'S' | 'C' | 'E'

function formatDate(date?: Date) {
  return date ? format(date, 'yyyy-MM-dd\'T\'HH:mm:ss') : undefined
}

export async function getQuestions() {
  let resp = await client.get<{question: string}[]>('/holland60/survey')
  return resp.data
}

export async function getExplanations() {
  let resp = await client.get<{
    [key in RIASCE]: {
      name: string,
      features: string,
      specialties: string,
      typicalCareer: string
    }
  }>('/holland60/explanations')
  return resp.data
}

export async function submit(answers: boolean[]) {
  if (answers.length !== 60) throw new Error('length of answers have to be 60')
  const dataBytes = new Uint8Array(8)
  answers.forEach((v, i) => {
    if (!v) return 
    dataBytes[Math.floor(i / 8)] |= 2**(i % 8)
  })
  const dataBase64 = btoa(String.fromCharCode(...dataBytes))
  
  const resp = await client.post<{
    id: number,
    scores: {
      R: number,
      I: number,
      A: number,
      S: number,
      E: number,
      C: number
    },
    preferSubjects: string[]
  }>('/holland60', {
    answers: dataBase64
  })
  
  return resp.data
}

export async function exportReports(from?: Date, end?: Date) {
  const resp = await client.get(
    `/holland60/xlsx?from=${formatDate(from) ?? ''}&to=${formatDate(end) ?? ''}`,
    {
      responseType: 'blob'
    }
  )
  
  const url = window.URL.createObjectURL(new Blob([resp.data]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute(
    'download',
    contentDisposition.parse(resp.headers['content-disposition']).parameters.filename ?? '导出.xlsx'
  )
  link.click()
}

export async function getResults(page: number, limit: number, from?: Date, to?: Date) {
  const resp = await client.get<{
    rows: {
      id: number,
      user: any,
      name: null | string,
      phone: null | string,
      requireSupport: boolean,
      answers: string,
      r: number,
      i: number,
      a: number,
      s: number,
      e: number,
      c: number,
      suggestSubjects: string,
      createTime: string
    }[],
    total: number
  }>('/holland60', {
    params: {
      page,
      limit,
      from: formatDate(from),
      end: formatDate(to)
    }
  })

  const rows = resp.data.rows.map(r => {
    const answerBytes = atob(r.answers)

    const answers = []
    for (let i = 0; i < 60; ++i) {
      const mask = 2**(i % 8)
      answers.push((answerBytes.charCodeAt(Math.floor(i / 8)) & mask) === mask)
    }

    return {
      ...r,
      answers
    }
  })

  return {
    rows,
    total: resp.data.total
  }
}


export async function requestSupport(id: number, submitter: {
  name: string,
  phone: string
}) {
  await client.post<null>(`/holland60/${id}/support`, submitter)
}