import {Backdrop, CircularProgress, Grid, makeStyles, Typography} from '@material-ui/core'
import omit from 'lodash/omit'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import React from 'react'

const useStyles = makeStyles({
  backdrop: {
    color: 'white'
  }
})

function LoadingCover(props: {
  msg?: string,
  errorMsg?: string,
  open?: boolean,
  error?: boolean
} & React.HTMLAttributes<HTMLDivElement>) {
  const {
    msg,
    open = true,
    errorMsg = '系统错误，点击重试',
    error = false
  } = props
  const classes = useStyles()
  
  return (
    <Backdrop className={classes.backdrop} open={open} {...omit(props, ['msg', 'status'])} transitionDuration={0} >
      <Grid container spacing={2} justify="center" alignItems="center" direction="column">
        {
          error ?
            <>
              <Grid item>
                <HighlightOffIcon style={{fontSize: '50px'}} />
              </Grid>
              <Grid item>
                {errorMsg}
              </Grid>
            </>:
            <>
              <Grid item>
                <CircularProgress color="inherit" />
              </Grid>
              <Grid item>
                <Typography color="inherit">{msg}</Typography>
              </Grid>
            </>
        }
      </Grid>
    </Backdrop>
  )
}

export default LoadingCover