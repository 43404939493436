import React from 'react'
import {useAppDispatch, useAppSelector} from '../../store/hooks'
import {selectors as authNSelectors, actions as authNActions} from '../../store/authn'
import useAsyncFn from 'react-use/lib/useAsyncFn'
import LoginBackdrop from './LoginBackdrop'
import ResultPage from './ResultPage'

export default function ExportPage() {
  // Admin user login
  const isAdmin = useAppSelector(authNSelectors.isAdmin)
  const dispatch = useAppDispatch()
  const [{ loading }, login] = useAsyncFn(async (key: string) => {
    await dispatch(authNActions.authNAdmin(key))
  }, [dispatch])

  return <>
    <LoginBackdrop isAdmin={isAdmin} loading={loading} login={login} />
    {isAdmin
      ? <ResultPage />
      : undefined
    }
  </>
}
