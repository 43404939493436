import React, {useCallback, useState} from 'react'
import {makeStyles, useTheme} from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Input from '@material-ui/core/Input'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import useAsyncFn from 'react-use/lib/useAsyncFn'
import Backdrop from '@material-ui/core/Backdrop'

const useStyles = makeStyles(theme => ({
  buttonProgress: {
    position: 'absolute',
    height: 18,
    width: 18,
    top: '50%',
    left: '50%',
    marginLeft: -9,
    marginTop: -9
  }
}))

const LoginBackdrop = (props: {
  isAdmin: boolean,
  login: (key: string) => any,
  loading: boolean
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const { isAdmin, login } = props
  
  const [key, setKey] = useState(isAdmin ? '................' : '')
  const [keyError, setKeyError] = useState(false)
  const [{ loading }, onLogin] = useAsyncFn(async () => {
    setKeyError(false)
    if (key.length === 0) {
      setKeyError(true)
      return
    }
    login(key)
  }, [isAdmin, key, setKeyError, login])
  const onPressInPassword = useCallback((event: { key: string }) => {
    if (event.key === 'Enter') onLogin()
  }, [onLogin])
  
  return <Backdrop open={!isAdmin} onClick={() => false}>
    <Paper style={{padding: theme.spacing(2)}}>
      <Grid container spacing={1}>
        <Grid item>
          <Input
            value={key}
            type="password"
            placeholder="请输入密钥"
            onChange={(v) => setKey(v.target.value)}
            error={keyError}
            disabled={loading}
            onKeyDown={onPressInPassword}
          />
        </Grid>
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            onClick={onLogin}
            disabled={loading}
          >
            {loading?
              <CircularProgress size={18} className={classes.buttonProgress} />
              :undefined
            }
            确认
          </Button>
        </Grid>
      </Grid>
    </Paper>
  </Backdrop>
}

export default LoginBackdrop
