import React from 'react'
import {useAppDispatch, useAppSelector} from '../store/hooks'
import {selectors as authNSelectors, actions as authNActions} from '../store/authn'
import {useAsyncRetry} from 'react-use'
import {unwrapResult} from '@reduxjs/toolkit'
import LoadingCover from './LoadingCover'

const AuthNGate = (props: React.PropsWithChildren<{}>) => {
  const authed = useAppSelector(authNSelectors.isAuthed)
  const dispatch = useAppDispatch()
  const loginState = useAsyncRetry(async () => {
    if (!authed) {
      try {
        return unwrapResult(await dispatch(authNActions.authNSubmitter()))
      } catch (e) {
        throw e
      }
    }
  }, [dispatch, authed])

  if (authed) {
    return <>{props.children}</>
  }
  
  return (
    <LoadingCover
      msg="登录中"
      error={!loginState.loading && loginState.error !== undefined}
      onClick={() => { loginState.error && !loginState.loading && loginState.retry() }}
    />
  )
}

export default AuthNGate