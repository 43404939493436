import React, { useCallback } from 'react'
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import CircularProgress from '@material-ui/core/CircularProgress'
import {useAsyncFn} from 'react-use'
import {preload as preloadHolland60Survey} from './Holland60Survey'
import {useHistory} from 'react-router-dom'
import {makeStyles} from '@material-ui/core/styles'
import {useAppSelector} from '../store/hooks'
import {selectors as holland60Selectors} from '../store/holland60'

const useStyles = makeStyles(theme => ({
  card: {
    margin: theme.spacing(1),
  },
  form: {
    paddingTop: theme.spacing(2),
  },
  actions: {
    justifyContent: 'flex-end',
    paddingTop: 0
  },
  buttonProgress: {
    position: 'absolute',
    height: 18,
    width: 18,
    top: '50%',
    left: '50%',
    marginLeft: -9,
    marginTop: -9
  }
}))

const SubmitterInfoForm = (props: { }) => {
  const classes = useStyles()
  const {replace} = useHistory()
  const canSubmit = useAppSelector(holland60Selectors.canSubmit)
  const answered = useAppSelector(s => holland60Selectors.answered(s) > 0)

  // Callbacks
  const [{loading: creatingSurvey}, newSurvey] = useAsyncFn(async () => {
    try {
      await preloadHolland60Survey()
      replace(`/survey?reset=${answered}`)
    } catch (e) {
      // ignore
    }
  }, [answered])
  const continueSurvey = useCallback(() => { replace('/survey') }, [replace])
  const submit = useCallback(() => { replace('/submit') }, [replace])

  return (
    <Container maxWidth="sm" disableGutters>
      <Card className={classes.card}>
        <CardContent>
          <Typography>
            请根据对每一题目的第一印象作答，不必仔细推敲，答案没有好坏、对错之分。根据自己的实际情况回答“是”或“否”。
          </Typography>
        </CardContent>
        <CardActions className={classes.actions}>
          {
            canSubmit
              ? <Button
                color={'primary'}
                disabled={creatingSurvey}
                onClick={submit}
              >
                查看结果
              </Button>
              : answered ?
                <Button
                  color={'primary'}
                  disabled={creatingSurvey}
                  onClick={continueSurvey}
                >
                继续答题
                </Button>
                : undefined
          }
          <Button
            color={'primary'}
            disabled={creatingSurvey}
            onClick={() => newSurvey()}
          >
            {creatingSurvey && <CircularProgress size={18} className={classes.buttonProgress}/>}
            {answered ? '重新开始' : '开始答题'}
          </Button>
        </CardActions>
      </Card>
    </Container>
  )
}

export default SubmitterInfoForm