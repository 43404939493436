import React from 'react'
import {Provider} from 'react-redux'
import store, { persistor } from './store'
import {PersistGate} from 'redux-persist/integration/react'
import LoadingCover from './components/LoadingCover'
import {SnackbarProvider} from 'notistack'
import Notifier from './components/Notifier'
import AuthNGate from './components/AuthNGate'
import {Switch, Route, BrowserRouter} from 'react-router-dom'
import Welcome from './pages/Welcome'
import Holland60Survey from './pages/Holland60Survey'
import ResetPage from './pages/Reset'
import SubmitPage from './pages/submit'
import ExporterPage from './pages/results'
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles'
import DateFnsUtils from '@date-io/date-fns'
import {MuiPickersUtilsProvider} from '@material-ui/pickers'
import {zhCN as muiLocale} from '@material-ui/core/locale'
import dateFnsLocale from 'date-fns/locale/zh-CN'

const theme = createMuiTheme({
  palette: {
    contrastThreshold: 2
  }
}, muiLocale)

function WithContext() {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate loading={<LoadingCover />} persistor={persistor}>
          <MuiThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={dateFnsLocale}>
              <SnackbarProvider maxSnack={3}>
                <Notifier />
                <AuthNGate>
                  <App />
                </AuthNGate>
              </SnackbarProvider>
            </MuiPickersUtilsProvider>
          </MuiThemeProvider>
        </PersistGate>
      </Provider>
    </BrowserRouter>
  )
}

function App() {
  return (
    <Switch>
      <Route path="/survey">
        <Holland60Survey />
      </Route>
      <Route path="/submit">
        <SubmitPage />
      </Route>
      <Route path="/reset">
        <ResetPage />
      </Route>
      <Route path="/export">
        <ExporterPage />
      </Route>
      <Route>
        <Welcome />
      </Route>
    </Switch>
  )
}

export default WithContext
