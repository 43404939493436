import React from 'react'
import { useSnackbar } from 'notistack'
import {useAppDispatch, useAppSelector} from '../store/hooks'
import {actions as notify} from '../store/notifier'
import _forEach from 'lodash/forEach'

const Notifier = () => {
  const dispatch = useAppDispatch()
  const notifications = useAppSelector(state => state.notifier.notifications)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  React.useEffect(() => {
    _forEach(notifications, (v, key) => {
      if (v === undefined) return
      if (v.displayed) return
      dispatch(notify.displayed(key))

      enqueueSnackbar(v.message, {
        key,
        ...v.options,
        onExited: (event, k) => {
          dispatch(notify.remove(k as string))
        },
      })
    })
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch])

  return null
}

export default Notifier